import React from "react";
import { graphql } from "gatsby";

import Button from "../components/Button";
import HeroContainer from "../components/HeroContainer";
import mail from "../../assets/images/mail.svg";

export const pageQuery = graphql`
  query cookieConsentQuery {
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const Thanks = () => (
  <div className="hero-error gradient thanks-hero">
    <div className="column-hero">
      <HeroContainer
        heroType="error"
        title="Thank you for your interest!"
        titleClass="full"
        paragraph="We are happy you showed interest in our services. We have received your message and we’ll get back to you as soon as possible."
        paragraphClass="p1 full"
      >
        <p className="p1 large" style={{ marginTop: 20 }}>
          {" "}
          Keep exploring our website to learn more about our work.
        </p>
        <Button
          buttonClass="button-container home"
          buttonHref="/services"
          buttonLabel="explore our services"
          buttonLine="button-box"
        />
      </HeroContainer>
    </div>
    <div className="column-mail">
      <img src={mail} alt="submited mail" className="mail-image" />
    </div>
  </div>
);

export default Thanks;
